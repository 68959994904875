
import {axios} from './init.js'
export function register(data) {
    return axios.post('/?_e=xv2.u.register',data)
}

export function login(data) {
    return axios.post('/?_e=xv2.u.login',data)
}

export function sendMail(data) {
    return axios.post('/?_e=xv2.u.send_mail',data)
}