let axios,
    myInterceptorList=[],
    setAxios = function (_axios, myInterceptor) {
        axios = _axios
        for (let i in myInterceptorList) {
            if(myInterceptorList[i]!=myInterceptor){
                axios.interceptors.response.eject(myInterceptorList[i])
            }
        }
        myInterceptorList.push(myInterceptor)
    }


export {
    axios,
    setAxios
}