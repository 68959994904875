<template>
  <el-container class="content-box">
    <el-row type="flex" align="middle" justify="center" :gutter="24">
      <el-col :sx="24" v-if="!loginStatus">
        <el-form status-icon label-width="75px" class="demo-ruleForm" ref="form" :model="AuthData">
          <el-form-item label="邮箱" prop="email">
            <el-input type="text" v-model="AuthData.email" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="AuthData.password" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="重复密码" prop="password" v-if="state=='REGISTER'">
            <el-input type="password" v-model="AuthData.password2" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="authcode" v-if="state=='REGISTER'" >
            <el-input type="text" v-model="AuthData.code" style="width: 40%"></el-input>
            <el-button :class="countDown>0?'':'user_info_btn'" :type="countDown>0?'info':'primary'"  @click="handleSendMail" style="margin:0 0 0 2%">{{send_mail_text}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="user_info_btn" type="primary" @click="handleDo" :model="doTitle">{{ doTitle }}</el-button>
            <el-button type="info" plain @click="handleTurn" :model="turnTitle">{{ turnTitle }}</el-button>
          </el-form-item>
        </el-form>
      </el-col >
      <el-form-item v-if="loginStatus">
        <el-button class="user_info_btn" type="primary" @click="userInfo" style="padding: 10px 40px">进入用户中心</el-button>
      </el-form-item>
    </el-row>
  </el-container>
</template>


<script>

import {setAxios} from '@/api/init'
import debounce from 'lodash.debounce'
import {register, login,sendMail} from "@/api/auth";
const STATE_LOGIN = 'LOGIN'
const STATE_REGISTER = 'REGISTER'
import {ElContainer,ElForm,ElFormItem,ElMessage}  from 'element-plus';
export default {
  name: 'User',
  components:{ElContainer,ElForm,ElFormItem,ElMessage},
  data() {
    return {
      state: STATE_LOGIN,
      AuthData: {
        password2: '',
        password: '',
        email: '',
        name: '',
        code: ''
      },
      doTitle: '登陆',
      turnTitle: '注册',
      code_loading: false,
      send_mail_text:'邮箱验证码',
      countDown:0
    };
  },
  computed:{
    loginStatus(){
      return /login_user=1/.test(window.document.cookie)
    }
  },

  watch: {
    state() {
      if (this.state == STATE_LOGIN) {
        this.doTitle = '登陆'
        this.turnTitle = '注册'
      } else if (this.state == STATE_REGISTER) {
        this.doTitle = '注册'
        this.turnTitle = '返回'
      }
    }
  },
  created(){},
  methods: {
    handleTurn() {
      if (this.state == STATE_LOGIN) {
        this.state = STATE_REGISTER
      } else if (this.state == STATE_REGISTER) {
        this.state = STATE_LOGIN
      }
    },
    userInfo(){
      window.location.href = '/?_e=xv2.u.u_info'
    },
    handleDo() {
      if (this.state == STATE_LOGIN) {
        this.handleLogin()
      } else if (this.state == STATE_REGISTER) {
        this.hadleRegister()
      }
    },
    hadleRegister() {
      var that = this
      register(this.AuthData)
          .then(function (response) {
            if (response.data && !response.data.errno) {
              that.state = STATE_LOGIN
            }
          })
          .catch()
    },
    handleLogin() {
      var that = this
      login(this.AuthData)
          .then(function (response) {
            if (response.data && response.data.errno==0) {
              that.afterLogin(response.data.data)
            }
          })
          .catch()
    },
    afterLogin(data) {
      if (this.$route.query.redirect) {
        this.$router.replace({'path': this.$route.query.redirect})
      } else {
        window.location.href = '/?_e=xv2.u.u_info'
      }
    },
    handleSendMail(){
      var that = this
      if(this.countDown>0){
        this.send_mail_text = '迟点再试'
        return
      }
      function reset(){
        that.countDown = 0
        that.send_mail_text = '邮箱验证码'
        clearInterval(t)
      }
      that.countDown = 60
      function f_count_down(){
        that.countDown--
        if(that.countDown<=0){
          reset()
        }else{
          that.send_mail_text = that.countDown+'s'
        }

      }
      f_count_down()
      let t = setInterval(function(){
        f_count_down()
      },1000)
      sendMail(this.AuthData)
          .then(function (response) {
            if (response.data && response.data.errno==0) {

            }else if(response.data.errno==401){
              reset()
            }
          })
          .catch()
    }
  },
  beforeMount() {
    var that = this
    var myInterceptor = this.axios.interceptors.response.use(function (response) {
      if (response.data && response.data.errno >0) {
        response.data.errstr && ElMessage({
          message: response.data.errstr,
          type: 'error'
        });
      } else {
        response.data.data && ElMessage({
          message: response.data.data,
          type: 'success',
          duration: 1000
        });

      }
      return response;
    }, function (error) {
      // Do something with response error
      return Promise.reject(error);
    });
    setAxios(this.axios, myInterceptor)
  }

}
</script>

<style scoped>
.el-row {
  width: 100%;
  height: 100%;
}

.el-header {
  color: #eee;
  background-color: #409EFF;
  line-height: 50px;
  height: 50px !important;
}

.el-container {
  height: 100%;

}

.content-box {
  justify-content: center;
  overflow-y: scroll;
  margin-bottom: -17px;
  margin-right: -17px;
  min-width: 370px;
}
.user_info_btn{
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #42b983;

}
.user_info_btn:active{
  opacity: .6;
}
</style>
